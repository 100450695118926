import {
  LOADING_ASSETS,
  LOADING_TPMS_STATUS_TRACTOR_ASSETS,
  LOADING_TPMS_STATUS_TRAILER_ASSETS,
  LOADING_GPS,
  LOADING_WARNINGS,
  SET_ASSETS,
  SET_TPMS_STATUS_TRACTOR_ASSETS,
  SET_TPMS_STATUS_TRAILER_ASSETS,
  SET_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS,
  SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT,
  SET_TPMS_STATUS_TRAILER_TOTAL_COUNT,
  SET_TPMS_STATUS_TRACTOR_COLUMNS,
  SET_TPMS_STATUS_TRAILER_COLUMNS,
  SET_GPS,
  SET_GPS_ERROR,
  SET_ASSETS_ERROR,
  SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR,
  SET_TPMS_STATUS_TRAILER_ASSETS_ERROR,
  SET_WARNINGS_ERROR,
  LOADING_MAP_DATA,
  LOADING_STATUS,
  SET_FILTER_MATCHES,
  SET_ACKNOWLEDGEMENTS,
  LOADING_ACKNOWLEDGEMENTS,
  SET_ACKNOWLEDGEMENTS_ERROR,
  SET_LOCATIONS,
  SET_LOCATIONS_STATUS,
  SET_ASSETS_COUNT,
  SET_FILTER,
  SET_ASSETS_LIMIT,
  SET_TPMS_STATUS_TRACTOR_LIMIT,
  SET_TPMS_STATUS_TRAILER_LIMIT,
  SET_ASSETS_OFFSET,
  SET_TPMS_STATUS_TRACTOR_OFFSET,
  SET_TPMS_STATUS_TRAILER_OFFSET,
  REMOVE_ASSET_GROUP_FILTER,
  SET_ASSETS_CARDS_DATA,
  SET_ASSETS_CARDS_DATA_LOADING,
  SET_FILTERS_FIELD,
  SET_FILTERS,
  SET_FILTER_PARAMS_FIELD,
  DELETE_FILTER_PARAMS_FIELD,
  SET_FILTER_PARAMS,
  SET_DASHBOARD_ACTIVE_TAB,
} from '../actionTypes'
import { convertDataEpochToDate, getErrorObj } from '../../utils'
import { getStatusFromState, getStatusObj } from '../../utils/utils'
import { store } from '../store'
import api from '../../services/api'
import MenuItemsHelper from '../../componentsV2/Menu/MenuItemsHelper'
import { sensorStatusItems as assetsTabHealthStatusItems } from '../../componentsV2/HealthStatusFilter/items'

export const loadingAssets = (isLoading) => ({
  type: LOADING_ASSETS,
  payload: isLoading,
})

export const setAssets = (assets) => ({
  type: SET_ASSETS,
  payload: assets,
})

export const setAssetsSortOptions = (options) => ({
  type: SET_ASSETS_SORT_OPTIONS,
  payload: options,
})

export const setTpmsStatusTractorAssetsSortOptions = (options) => ({
  type: SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS,
  payload: options,
})

export const setTpmsStatusTrailerAssetsSortOptions = (options) => ({
  type: SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS,
  payload: options,
})

export const setFilterMatches = (assetIds) => ({
  type: SET_FILTER_MATCHES,
  payload: assetIds,
})

export const setAssetsError = (error) => ({
  type: SET_ASSETS_ERROR,
  payload: error,
})

export const loadingTpmsStatusTractorAssets = (isLoading) => ({
  type: LOADING_TPMS_STATUS_TRACTOR_ASSETS,
  payload: isLoading,
})

export const loadingTpmsStatusTrailerAssets = (isLoading) => ({
  type: LOADING_TPMS_STATUS_TRAILER_ASSETS,
  payload: isLoading,
})

export const setTpmsStatusTractorAssets = (assets) => ({
  type: SET_TPMS_STATUS_TRACTOR_ASSETS,
  payload: assets,
})

export const setTpmsStatusTrailerAssets = (assets) => ({
  type: SET_TPMS_STATUS_TRAILER_ASSETS,
  payload: assets,
})

export const setTpmsStatusTractorTotalCount = (count) => ({
  type: SET_TPMS_STATUS_TRACTOR_TOTAL_COUNT,
  payload: count,
})

export const setTpmsStatusTrailerTotalCount = (count) => ({
  type: SET_TPMS_STATUS_TRAILER_TOTAL_COUNT,
  payload: count,
})

export const setTpmsStatusTractorColumns = (columns) => ({
  type: SET_TPMS_STATUS_TRACTOR_COLUMNS,
  payload: columns,
})

export const setTpmsStatusTrailerColumns = (columns) => ({
  type: SET_TPMS_STATUS_TRAILER_COLUMNS,
  payload: columns,
})

export const setTpmsStatusTractorAssetsError = (error) => ({
  type: SET_TPMS_STATUS_TRACTOR_ASSETS_ERROR,
  payload: error,
})

export const setTpmsStatusTrailerAssetsError = (error) => ({
  type: SET_TPMS_STATUS_TRAILER_ASSETS_ERROR,
  payload: error,
})

export const loadingWarnings = (id, isLoading) => ({
  type: LOADING_WARNINGS,
  payload: { id, isLoading },
})

export const setWarningsError = (id, error) => ({
  type: SET_WARNINGS_ERROR,
  payload: { id, error },
})

export const loadingMapData = (isLoading) => ({
  type: LOADING_MAP_DATA,
  payload: isLoading,
})

export const loadingStatus = (isLoading) => ({
  type: LOADING_STATUS,
  payload: isLoading,
})

export const loadingGps = (id, isLoading) => ({
  type: LOADING_GPS,
  payload: { id, isLoading },
})

export const setGps = (id, data) => ({
  type: SET_GPS,
  payload: { id, data },
})

export const setGpsError = (id, error) => ({
  type: SET_GPS_ERROR,
  payload: { id, error },
})

export const loadingAcknowledgements = (isPosting) => ({
  type: LOADING_ACKNOWLEDGEMENTS,
  payload: isPosting,
})

export const setAcknowledgementsError = (error) => ({
  type: SET_ACKNOWLEDGEMENTS_ERROR,
  payload: error,
})

export const setAcknowledgements = (acknowledgements) => ({
  type: SET_ACKNOWLEDGEMENTS,
  payload: acknowledgements,
})

export const setLocations = (locations) => ({
  type: SET_LOCATIONS,
  payload: locations,
})

export const setLocationsStatus = (status) => ({
  type: SET_LOCATIONS_STATUS,
  payload: status,
})

export const setAssetsCount = (assetsCount) => ({
  type: SET_ASSETS_COUNT,
  payload: assetsCount,
})

export const setFilter = (filter) => ({
  type: SET_FILTER,
  payload: filter,
})

export const setFilterParams = (payload) => ({
  type: SET_FILTER_PARAMS,
  payload,
})

export const setFilterParamsField = (key, value) => ({
  type: SET_FILTER_PARAMS_FIELD,
  payload: { key, value },
})

export const deleteFilterParamsField = (key) => ({
  type: DELETE_FILTER_PARAMS_FIELD,
  payload: key,
})

export const setFilters = (payload) => ({
  type: SET_FILTERS,
  payload,
})

export const setFiltersField = (key, value) => ({
  type: SET_FILTERS_FIELD,
  payload: { key, value },
})

export const setAssetsLimit = (limit) => ({
  type: SET_ASSETS_LIMIT,
  payload: limit,
})

export const setTpmsStatusTractorAssetsLimit = (limit) => ({
  type: SET_TPMS_STATUS_TRACTOR_LIMIT,
  payload: limit,
})

export const setTpmsStatusTrailerAssetsLimit = (limit) => ({
  type: SET_TPMS_STATUS_TRAILER_LIMIT,
  payload: limit,
})

export const setAssetsOffset = (offset) => ({
  type: SET_ASSETS_OFFSET,
  payload: offset,
})

export const setTpmsStatusTractorAssetsOffset = (offset) => ({
  type: SET_TPMS_STATUS_TRACTOR_OFFSET,
  payload: offset,
})

export const setTpmsStatusTrailerAssetsOffset = (offset) => ({
  type: SET_TPMS_STATUS_TRAILER_OFFSET,
  payload: offset,
})

export const removeAssetGroupFilter = () => ({
  type: REMOVE_ASSET_GROUP_FILTER,
  payload: null,
})

export const setAssetsCardsData = (payload) => ({
  type: SET_ASSETS_CARDS_DATA,
  payload,
})

export const setAssetsCardsDataLoading = (payload) => ({
  type: SET_ASSETS_CARDS_DATA_LOADING,
  payload,
})

export const setDashboardActiveTab = (payload) => ({
  type: SET_DASHBOARD_ACTIVE_TAB,
  payload,
})

export function fetchPagedTpmsStatusTractorAssets() {
  return function (dispatch, getState) {
    const type = 'tractor'
    const {
      limit,
      offset,
      sortOptions: { column, direction },
    } = getState().dash.tpmsStatusTractorAssets

    const filterParams = { ...getState().dash.filterParams }
    delete filterParams['sensor_type']
    const filter = composeFilterQuery(filterParams)

    const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets

    dispatch(loadingTpmsStatusTractorAssets(true))
    return api
      .getTirePressureAssets(
        limit,
        offset,
        type,
        filter,
        column,
        direction,
        hideAutogeneratedAssets,
      )
      .then((res) => {
        dispatch(setTpmsStatusTractorAssets(res.paged_data.data))
        dispatch(setTpmsStatusTractorTotalCount(res.total_count))
        dispatch(setTpmsStatusTractorColumns(res.paged_data.columns))
        dispatch(setTpmsStatusTractorAssetsError(getErrorObj()))
      })
      .catch((error) =>
        dispatch(setTpmsStatusTractorAssetsError(getErrorObj(error))),
      )
      .finally(() => dispatch(loadingTpmsStatusTractorAssets(false)))
  }
}

export function fetchPagedTpmsStatusTrailerAssets() {
  return function (dispatch, getState) {
    const type = 'trailer'
    const {
      limit,
      offset,
      sortOptions: { column, direction },
    } = getState().dash.tpmsStatusTrailerAssets

    const filterParams = { ...getState().dash.filterParams }
    delete filterParams['sensor_type']
    const filter = composeFilterQuery(filterParams)

    const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets

    dispatch(loadingTpmsStatusTrailerAssets(true))
    return api
      .getTirePressureAssets(
        limit,
        offset,
        type,
        filter,
        column,
        direction,
        hideAutogeneratedAssets,
      )
      .then((data) => {
        dispatch(setTpmsStatusTrailerAssets(data.paged_data.data))
        dispatch(setTpmsStatusTrailerTotalCount(data.total_count))
        dispatch(setTpmsStatusTrailerColumns(data.paged_data.columns))
        dispatch(setTpmsStatusTrailerAssetsError(getErrorObj()))
      })
      .catch((error) =>
        dispatch(setTpmsStatusTrailerAssetsError(getErrorObj(error))),
      )
      .finally(() => dispatch(loadingTpmsStatusTrailerAssets(false)))
  }
}

export const composeLocationsWarnings = (data) => {
  return data.map((item) => {
    let hasCriticalWarning = false
    let hasWarning = false

    if (item.warning_type) {
      item.warning_type.split(',').forEach((wt) => {
        // ignore no_status warnings
        if (wt.includes('no_status')) {
          return
        }

        if (
          ['critical', 'abnormal_high', 'low_line_pressure'].some((w) =>
            wt.includes(w),
          )
        ) {
          hasCriticalWarning = true
        } else {
          hasWarning = true
        }
      })
    }

    item.hasCriticalWarning = hasCriticalWarning
    item.hasWarning = hasWarning

    return item
  })
}

export function fetchLocations() {
  return async function (dispatch, getState) {
    dispatch(loadingMapData(true))
    const filter = composeFilterQuery(getState().dash.filterParams)

    const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets

    const queryParams = new URLSearchParams(filter)

    if (hideAutogeneratedAssets) {
      queryParams.append('hide_autogenerated', 'true')
    }

    return api
      .getLatestGpsV2(queryParams.toString())
      .then((locations) => {
        locations.forEach((loc) => {
          const warningInfo = getStatusFromState(loc.warning_state)
          loc.hasCriticalWarning =
            warningInfo.tpms.critWarn ||
            warningInfo.smartHub.critWarn ||
            warningInfo.linePressure.critWarn
          loc.hasWarning = warningInfo.tpms.warn || warningInfo.smartHub.warn
          delete loc.warning_state
        })

        dispatch(setLocations(locations))
        dispatch(setLocationsStatus(getStatusObj()))
      })
      .catch((error) => {
        console.log(error)
        dispatch(setLocationsStatus(getStatusObj(error)))
      })
      .finally(() => dispatch(loadingMapData(false)))
  }
}

export const fetchPagedAssets = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingAssets(true))

      const {
        assets: {
          offset,
          limit,
          sortOptions: { column, direction },
        },
      } = getState().dash

      const filter = composeFilterQuery(getState().dash.filterParams)

      const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets
      const queryParams = new URLSearchParams(filter)

      queryParams.append('offset', offset)
      queryParams.append('limit', limit)
      if (column && direction) {
        queryParams.append('sort_column', column)
        queryParams.append('sort_direction', direction)
      }
      if (hideAutogeneratedAssets) {
        queryParams.append('hide_autogenerated', 'true')
      }

      const assetsRes = await api.getAssetsV2(queryParams.toString())

      const pagedAssets = convertDataEpochToDate(
        assetsRes.paged_data,
        'last_gps_update_epoch',
        'datetime',
      )
      dispatch(setAssets(pagedAssets))
      dispatch(setAssetsCount(assetsRes.total_count))
      dispatch(setAssetsError(getErrorObj()))
    } catch (err) {
      dispatch(setAssetsError(getErrorObj(err)))
    } finally {
      dispatch(loadingAssets(false))
    }
  }
}

export const fetchAssetsCardsData = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAssetsCardsDataLoading(true))

      const filters = composeFilterQuery(getState().dash.filterParams)

      const hideAutogeneratedAssets = getState().common.hideAutogeneratedAssets

      const queryParams = new URLSearchParams(filters)

      if (hideAutogeneratedAssets) {
        queryParams.append('hide_autogenerated', 'true')
      }

      const assetCardsData = await api.getAssetsCardsData(
        queryParams.toString(),
      )

      dispatch(setAssetsCardsData(assetCardsData))
    } catch (err) {
    } finally {
      dispatch(setAssetsCardsDataLoading(false))
    }
  }
}

export const filterTabByAssetHealthTooltipItem = (warningCase) => {
  return (dispatch, getState) => {
    const activeTab = getState().dash.activeTab

    if (activeTab === 'assets') {
      const healthStatusItems = getState().dash.filters.healthStatus

      const matchedItem = healthStatusItems.find(
        (item) => item.id === warningCase,
      )

      if (!matchedItem) return

      const items = MenuItemsHelper.copyDeep(healthStatusItems)

      MenuItemsHelper.handleItemChange(items, {
        ...matchedItem,
        checked: false,
      })

      dispatch(setFiltersField('healthStatus', items))

      const healthStatusParentIdsToSkip = [
        'tire',
        'hub',
        'line_pressure',
        'no_status',
        'asset_state',
        'asset_speed',
        'pressure_fast_leak',
        'geofence',
      ]

      dispatch(
        setFilterParamsField(
          'status_2',
          items
            .filter(
              (item) =>
                item.checked && !healthStatusParentIdsToSkip.includes(item.id),
            )
            .map((item) => item.id),
        ),
      )

      dispatch(setAssetsOffset(0))
      dispatch(fetchPagedAssets())
      dispatch(fetchLocations())
      dispatch(fetchAssetsCardsData())
    } else if (activeTab === 'tpmsStatus') {
      const healthStatusItems = getState().dash.filters.healthStatus

      const matchedItem = healthStatusItems.find(
        (item) => item.id === warningCase,
      )

      if (!matchedItem) return

      const items = MenuItemsHelper.copyDeep(healthStatusItems)

      MenuItemsHelper.handleItemChange(items, {
        ...matchedItem,
        checked: false,
      })

      dispatch(setFiltersField('healthStatus', items))

      dispatch(
        setFilterParamsField(
          'warnings_subtype',
          items.filter((item) => item.checked).map((item) => item.id),
        ),
      )

      dispatch(setTpmsStatusTractorAssetsOffset(0))
      dispatch(setTpmsStatusTrailerAssetsOffset(0))
      dispatch(fetchLocations())
      dispatch(fetchAssetsCardsData())
    }
  }
}

export const resetAssetsTabFilters = () => {
  return (dispatch) => {
    dispatch(setFilterParams({}))
    dispatch(setFiltersField('healthStatus', assetsTabHealthStatusItems))
  }
}

function composeFilterQuery(filterParams) {
  const query = new URLSearchParams()

  Object.entries(filterParams).forEach((filter) => {
    const key = filter[0]
    filter[1].forEach((value) => query.append('filters', `${key}=${value}`))
  })

  return query.toString()
}

store.subscribe(() => {
  const lastAction = store.getState().lastAction

  if (
    lastAction.type === SET_TPMS_STATUS_TRACTOR_ASSETS_SORT_OPTIONS ||
    lastAction.type === SET_TPMS_STATUS_TRACTOR_OFFSET
  ) {
    store.dispatch(fetchPagedTpmsStatusTractorAssets())
  }
  if (
    lastAction.type === SET_TPMS_STATUS_TRAILER_ASSETS_SORT_OPTIONS ||
    lastAction.type === SET_TPMS_STATUS_TRAILER_OFFSET
  ) {
    store.dispatch(fetchPagedTpmsStatusTrailerAssets())
  }
})
